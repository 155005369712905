import { FC, useEffect, useMemo, useState } from "react";

import { DatePicker, Form, Input, Select, TabsProps, Tabs, Button } from "antd";
import debounce from "lodash.debounce";
import dayjs from "dayjs";

import {
  updateEquipmentsFilter,
  updateEquipmentsPagination,
  updateEquipmentsSort,
} from "store/listEquipment/listEquipment.actions";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import filterIcon from "data/icons/filter.svg";
import searchIcon from "data/icons/search.svg";
import { IListEquipmentFilter } from "store/listEquipment/listEquipment.types";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";
import { initialState } from "store/listEquipment/listEquipment.reducer";
import { useContractors } from "helpers/useContractors";

import "./ListEquipmentFilterMobile.scss";

interface IFilter extends IListEquipmentFilter {
  date: any[];
}

const tabs: TabsProps[ "items" ] = [
  {
    key: "active",
    label: "Active",
  },
  {
    key: "expired",
    label: "Expired",
  },
  {
    key: "pending",
    label: "Pending",
  },
  {
    key: "all",
    label: "All",
  },
]

const ListEquipmentFilterMobile: FC = () => {
  const dispatch = useAppDispatch()
  const { isAdmin } = useAppSelector(getUserSelector);
  const { filter } = useAppSelector(getListEquipmentSelector);

  const { contractors, getContractors } = useContractors(100);

  const [ isFilterOpen, setIsFilterOpen ] = useState(false);

  useEffect(() => {
    isAdmin && getContractors("");

    return () => {
      dispatch(updateEquipmentsFilter(initialState.filter));
      dispatch(updateEquipmentsSort(initialState.sort));
      dispatch(updateEquipmentsPagination(initialState.pagination.page));
    }
  }, [])

  const handleChangeFilter = (_: Partial<IListEquipmentFilter>, values: IFilter) => {
    if (_.client && isAdmin) {
      getContractors("");
    }
    dispatch(updateEquipmentsFilter({
      ...values,
      date_begin: values.date && values.date[ 0 ] ? dayjs(values.date[ 0 ]).format("YYYY-MM-DD") : "",
      date_end: values.date && values.date[ 1 ] ? dayjs(values.date[ 1 ]).format("YYYY-MM-DD") : "",
    }));
    dispatch(updateEquipmentsPagination(1))
  }

  const handleChangeSubset = (subset: string) => {
    dispatch(updateEquipmentsFilter({
      ...filter,
      subset: subset as "active" | "expired" | "pending" | "all",
    }))
    dispatch(updateEquipmentsPagination(1))
    updateInfo();
  }

  const updateInfo = () => {
    dispatch(getEquipments());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <>
      <Form
        className="filter-wrapper mobile"
        layout="vertical"
        initialValues={ filter }
        onValuesChange={ (e, values) => {
          handleChangeFilter(e, values);
          debouncedResults();
        } }
      >
        <div className="mobile-search-wrapper">
          <Form.Item
            name="search"
          >
            <Input
              prefix={ <img src={ searchIcon } alt="" /> }
              placeholder="Search for equipment"
            />
          </Form.Item>

          <Button
            className="filter-icon"
            icon={ <img src={ filterIcon } alt="" /> }
            type="text"
            onClick={ () => setIsFilterOpen(!isFilterOpen) }
          />
        </div>

        <div className={ `mobile-filter ${ isFilterOpen ? "visible" : "" }` }>
          <Form.Item
            label="Contractor"
            name="client"
          >
            <Select
              allowClear
              filterOption={ false }
              onSearch={ (value) => getContractors(value) }
              options={ contractors }
              showSearch
            />
          </Form.Item>

          <Form.Item
            label="Start date"
            name="date_start"
          >
            <DatePicker
              style={ { width: "100%" } }
              placeholder="Start Date"
            />
          </Form.Item>

          <Form.Item
            label="End date"
            name="end_date"
          >
            <DatePicker
              style={ { width: "100%" } }
              placeholder="End Date"
            />
          </Form.Item>
        </div>
      </Form>

      <div className="list-equipment-tabs">
        <Tabs
          items={ tabs }
          activeKey={ filter.subset }
          onChange={ handleChangeSubset }
        />
      </div>
    </>
  )
}

export default ListEquipmentFilterMobile;
