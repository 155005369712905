import { Button, Form, Input } from "antd";

import checkCircle from "data/icons/check-circle.svg";
import closeCircle from "data/icons/close-circle.svg";
import { IOrganizationRegister } from "store/contracts/contracts.types";
import { formatPhoneNumber } from "services/data.service";

import "./InviteComplete.scss";

interface IProps {
	error: boolean;
	handleSubmit: (values: IOrganizationRegister) => void;
}

const InviteComplete = ({ error, handleSubmit }: IProps) => {
	const [ form ] = Form.useForm<IOrganizationRegister>();

	return (
		<div className="invite-complete-wrapper">
			<div className={ `notify ${ error ? "notify-error" : "notify" }` }>
				<img
					alt=""
					src={ error ? closeCircle : checkCircle }
				/>

				Your invite is {error ? "invalid" : "valid"}
			</div>

			<div className="form">
				<div className="form-title">
					Create organization account
				</div>

				<Form
					className="registration-form-wrapper"
					layout="vertical"
					onFinish={ handleSubmit }
					form={ form }
				>
					<Form.Item
						label="Official Name"
						name="org_official_name"
						rules={ [ { required: true, message: "Please input organization official name!" } ] }
					>
						<Input
							disabled={ error }
							placeholder="Add Official Name"
						/>
					</Form.Item>

					<Form.Item
						label="Short Name"
						name="org_name"
						rules={ [ { required: true, message: "Please input organization short name!" } ] }
					>
						<Input
							disabled={ error }
							placeholder="Add Short Name"
						/>
					</Form.Item>

					<div style={ { display: "flex" } }>
						<Form.Item
							label="Email"
							name="org_default_email"
							rules={ [
								{ required: true, message: "Please input organization email!" },
								{ type: "email", message: "Please input correct email!" },
							] }
							style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
						>
							<Input
								disabled={ error }
								placeholder="yourname@mail.com"
							/>
						</Form.Item>

						<Form.Item
							label="Phone"
							name="org_phone"
							style={ { width: "calc(50% - 10px)", display: "inline-block" } }
						>
							<Input
								disabled={ error }
								onChange={ (e) => {
									form.setFieldsValue({ org_phone: formatPhoneNumber(e.target.value) })
								} }
								placeholder="+375 (00) 000-00-00"
							/>
						</Form.Item>
					</div>

					<div className="hr" />

					<div className="form-subtitle">
						User info
					</div>

					<div style={ { display: "flex" } }>
						<Form.Item
							label="First Name"
							name="first_name"
							rules={ [ { required: true, message: "Please input user first name!" } ] }
							style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
						>
							<Input
								disabled={ error }
								placeholder="Add First Name"
							/>
						</Form.Item>

						<Form.Item
							label="Last Name"
							name="last_name"
							rules={ [ { required: true, message: "Please input user last name!" } ] }
							style={ { width: "calc(50% - 10px)", display: "inline-block" } }
						>
							<Input
								disabled={ error }
								placeholder="Add Last Name"
							/>
						</Form.Item>
					</div>

					<div style={ { display: "flex" } }>
						<Form.Item
							label="Email"
							name="email"
							rules={ [
								{ required: true, message: "Please input user email!" },
								{ type: "email", message: "Please input correct email!" },
							] }
							style={ { width: "calc(50% - 10px)", display: "inline-block", marginRight: "20px" } }
						>
							<Input
								disabled={ error }
								placeholder="yourname@mail.com"
							/>
						</Form.Item>

						<Form.Item
							label="Password"
							name="password"
							rules={ [ { required: true, message: "Please input user password!" } ] }
							style={ { width: "calc(50% - 10px)", display: "inline-block" } }
						>
							<Input.Password disabled={ error } />
						</Form.Item>
					</div>

					<Button
						disabled={ error }
						className="form-button"
						type="primary"
						htmlType="submit"
					>
						Create account
					</Button>
				</Form>
			</div>
		</div>
	);
};

export default InviteComplete;
