import { Button } from "antd";

import { InviteComplete } from "components/common";
import { useInviteComplete } from "helpers/useInviteComplete";
import mainLogo from "data/icons/hydra-logo-dark.svg";
import closeCircle from "data/icons/close-circle.svg";
import accountCreateSuccess from "data/images/account-create-success.svg";

import "./InviteCompletePage.scss";

const InviteCompletePage = () => {
  const {
    error,
    success,
    info,
    isAuthorized,
    isAuthorizationLoading,
    handleSubmit,
    acceptInvite,
  } = useInviteComplete();

  if (isAuthorizationLoading) {
    return (
      <div className="invite-complete"/>
    )
  }

  if (success) {
    return (
      <div className="invite-complete">
        <div className="invite-complete-left invite-complete-left-success">
          <div className="invite-complete-left-title">
            Account created <br/> successfully
          </div>

          <img
            alt=""
            className="img"
            src={ accountCreateSuccess }
          />
        </div>

        <div className="invite-complete-right invite-complete-right-success">
          <div className="title">
            Invitation to join to contract
          </div>

          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>

          <Button
            type="primary"
            onClick={ acceptInvite }
          >
            Accept Invitation
          </Button>

          <img
            alt=""
            className="logo"
            src={ mainLogo }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="invite-complete">
      {!isAuthorized && (
        <div className="invite-complete-left">
          <InviteComplete
            error={ error }
            handleSubmit={ handleSubmit }
          />
        </div>
      )}

      <div className="invite-complete-right">
        {isAuthorized && error && (
          <div className="notify-error notify-auth">
            <img
              alt=""
              src={ closeCircle }
            />

            Your invite is invalid
          </div>
        )}

        <div className="title">
          Invite to join to contract
        </div>

        {isAuthorized ? (
          <div className="text">
            Organization <span className="text-accent">{info?.inviter}</span> invited
            your organization to join to contract <span className="text-accent">{info?.contract}</span> on Hydra Platform
          </div>
        ) : (
          <div className="text">
            Organization <span className="text-accent">ООО “Официальное название компании”</span> invited
            your organization to join to contract <span className="text-accent">0000-00/АА</span> on Hydra Platform
          </div>
        )}

        <Button
          onClick={ acceptInvite }
          type="primary"
        >
          Accept invite
        </Button>

        {(!isAuthorized || error) && (
          <>
            <div className="blur"/>

            <img
              alt=""
              className="logo"
              src={ mainLogo }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InviteCompletePage;
