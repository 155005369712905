import { InviteComplete } from "components/common";
import { useInviteComplete } from "helpers/useInviteComplete";

import "./InviteOrgCompletePage.scss";

const InviteOrgCompletePage = () => {
  const {
    error,
    isAuthorized,
    isAuthorizationLoading,
    handleSubmit,
  } = useInviteComplete();

  if (isAuthorizationLoading) {
    return (
      <div className="invite-org-complete"/>
    )
  }

  return (
    <div className="invite-org-complete">
      {!isAuthorized && (
        <div className="invite-org-complete-main">
          <InviteComplete
            error={ error }
            handleSubmit={ handleSubmit }
          />
        </div>
      )}
    </div>
  );
};

export default InviteOrgCompletePage;
