import { useEffect, useState } from "react";

import { message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import { authorizeUserSuccess } from "store/user/user.actions";
import { getUserSelector } from "store/user/user.selector";
import { useAppDispatch, useAppSelector } from "store";
import { RouteLinks } from "services/router.service";
import api from "services/api.service";

export const useInviteComplete = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isAuthorized, isAuthorizationLoading } = useAppSelector(getUserSelector);

	const [ urlSearchParams ] = useSearchParams();
	const token = urlSearchParams.get("token") || "";

	const [ error, setError ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	const [ info, setInfo ] = useState<{ inviter: string; contract: string }>();

	const handleSubmit = async (values: any) => {
		try {
			await api.organizationInvitationRegister(values, token);

			try {
				const response = await api.authorizeUser(values.email, values.password);

				localStorage.setItem("AUTH_TOKEN", response.data.access);
				// setAccessToken(response.data.access);

				setTimeout(async () => {
					await invitationInfo();

					setSuccess(true);
				}, 0);
			} catch (error) {
				console.log(error);
			}

		} catch (error) {
			// @ts-ignore
			message.error(error.response.data.detail || "Something went wrong!");
		}
	};

	const invitationCheck = async () => {
		try {
			await api.contractInvitationCheck(token);

			setError(false);
		} catch (e) {
			setError(true);
		}
	};
	const invitationInfo = async () => {
		try {
			const response = await api.contractInvitationInfo(token);

			if (response.data) {
				setInfo(response.data);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const acceptInvite = async () => {
		try {
			const response = await api.contractInvitationAccept(token);

			if (response) {
				dispatch(authorizeUserSuccess());
				navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + response.data.contract);
			}

		} catch (error) {
			// @ts-ignore
			message.error(error.response.data.detail || "Something went wrong!");
		}
	};

	useEffect(() => {
		invitationCheck();
	}, []);

	useEffect(() => {
		if (isAuthorized) {
			invitationInfo();
		}
	}, [ isAuthorized ]);

	return {
		error,
		success,
		info,
		isAuthorized,
		isAuthorizationLoading,
		handleSubmit,
		acceptInvite,
	};
};
