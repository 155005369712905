import  { useEffect, useState } from "react";

import { Tabs, TabsProps } from "antd";

import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import LogItem from "components/cases/LogItem";

import "./CaseComments.scss";

const CaseComments = ({ onLoadActionsLog, actionsLog  }: { onLoadActionsLog: () => Promise<void>, actionsLog: any}) => {
  const { isMobile } = useAppSelector(getUserSelector);

  const [ activeTab, setActiveTab ] = useState<"comments" | "actions_log">("actions_log");

  useEffect(() => {
    onLoadActionsLog();
  }, []);

  const tabs: TabsProps[ "items" ] = [
    // {
    //   key: "comments",
    //   label: (
    //     <span style={ { display: "flex" } }>
    //       Comments
    //       <p className="clients-count-value">
    //         { comments.length }
    //       </p>
    //     </span>
    //   ),
    // },
    {
      key: "actions_log",
      label: (
        <span style={ { display: "flex" } }>
          Actions log
          <p className="clients-count-value">
            { actionsLog?.count }
          </p>
        </span>
      ),
    },
  ];

  return (
    <div className={ `case-comments-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Tabs
        items={ tabs }
        activeKey={ activeTab }
        onChange={ (e) => setActiveTab(e as "comments" | "actions_log") }
      />
      {/*{ activeTab === "comments"  && (*/}
      {/*  <>*/}
      {/*    <Loading*/}
      {/*      isLoading={ isCommentsLoading }*/}
      {/*      isFullWidth*/}
      {/*      height={ 200 }*/}
      {/*      isColored*/}
      {/*    >*/}
      {/*      { comments.map((comment) => (*/}
      {/*        <Comment*/}
      {/*          comment={ comment }*/}
      {/*          key={ comment.id }*/}
      {/*        />*/}
      {/*      )) }*/}
      {/*    </Loading>*/}

      {/*    <AddComment />*/}
      {/*  </>*/}
      {/*)}*/}

      { activeTab === "actions_log" && actionsLog && (
        actionsLog.results.map((log: any) => (
          <LogItem
            key={ log.id }
            log={ log }
          />
        ))
      )}
    </div>
  )
}

export default CaseComments;
