import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Button, DatePicker, Form, Input, Menu, message, Popover, Select } from "antd";
import type { MenuProps } from "antd";

import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";
import checkGreen from "data/icons/check-green.svg";
import { IContract } from "store/contracts/contracts.types";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { ContractInfoSlaModal, ContractTerminateModal, ContractPublishModal } from "components/contracts";
import { useContractors } from "helpers/useContractors";
import editIcon from "data/icons/edit.svg";
import arrowUp from "data/icons/arrow-up.svg";
import dotsMenu from "data/icons/dots-menu.svg";
import closeRed from "data/icons/close-red.svg";
import eyeGray from "data/icons/eye-gray.svg";
import hydraAccount from "data/icons/hydra-account.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import handshakeMiniIcon from "data/icons/handshake-mini.svg";
import handshakeBlueMiniIcon from "data/icons/handshake-blue-mini.svg";
import checkIcon from "data/icons/check.svg";
import closeIcon from "data/icons/close.svg";
import api from "services/api.service";

import "./ContractInfoForm.scss";

// eslint-disable-next-line @typescript-eslint/naming-convention
type MenuItem = Required<MenuProps>["items"][number];

const statusIcons = {
	active: checkGreen,
	draft: pending,
	pending: pending,
	expired: expired,
};

interface IProps {
	contractEdit: boolean;
	setContractEdit: (value: boolean) => void;
	onSubmitEquipments: (contractId: number) => Promise<IEquipmentSource[]>;
}

const ContractInfoForm = ({ contractEdit, setContractEdit, onSubmitEquipments }: IProps) => {
	const dispatch = useAppDispatch();
	const [ form ] = Form.useForm();

	const { currentContract } = useAppSelector(getContractsSelector);
	const { contractors, getContractors } = useContractors();

	const [ categories, setCategories ] = useState<{ value: number, label: string }[]>([]);
	const [ isSlaModalOpen, setSlaModalOpen ] = useState<boolean>(false);
	const [ slaIdNew, setSlaIdNew ] = useState<number>();
	const [ isLoading, setIsLoading ] = useState(false);

	const [ isTerminateModal, setIsTerminateModal ] = useState(false);
	const [ inviteContractorModal, setInviteContractorModal ] = useState("");
	const [ isMenuVisible, setIsMenuVisible ] = useState(false);

	const [ isAliasEdit, setIsAliasEdit ] = useState(false);
	const [ editingAlias, setEditingAlias ] = useState("");

	const [ isInformationEdit, setIsInformationEdit ] = useState(false);
	const [ editingInformation, setEditingInformation ] = useState("");

	const initialValues = currentContract ? {
		end_user: currentContract.end_user,
		identifier: currentContract.identifier,
		contractor: currentContract?.customer ? {
			value: currentContract?.customer?.id, label: currentContract?.customer?.name,
		} : null,
		subcontractor: currentContract?.service_provider ? {
			value: currentContract?.service_provider?.id, label: currentContract?.service_provider?.name,
		} : null,
		date_of_signing: currentContract.date_of_signing ? dayjs(currentContract.date_of_signing) : null,
		date_begin: currentContract.date_begin ? dayjs(currentContract.date_begin) : null,
		date_end: currentContract.date_end ? dayjs(currentContract.date_end) : null,
		alias: currentContract.meta.alias,
		information: currentContract.meta.information,
		category: currentContract.category ? currentContract?.category?.id : null,
	} : {};

	const getCategories = async () => {
		const response = await api.getContractCategories();

		setCategories(response.data.results.map((el) => ({ value: el.id, label: el.name })));
	};

	const handleCompleteForm = async (values: any) => {
		try {
			if (currentContract) {
				setIsLoading(true);

				await api.updContract(currentContract.id, {
					...values,
					date_of_signing: values.date_of_signing ? dayjs(values.date_of_signing).format("YYYY-MM-DD") : null,
					date_begin: values.date_begin ? dayjs(values.date_begin).format("YYYY-MM-DD") : null,
					date_end: values.date_end ? dayjs(values.date_end).format("YYYY-MM-DD") : null,
				});

				await api.setContractAlias(values.alias, currentContract.id);
				await onSubmitEquipments(currentContract.id);

				dispatch(getContract(currentContract.id));
				dispatch(getContractEquipments(+currentContract.id));

				setContractEdit(false);
				setIsLoading(false);
			}
		} catch (e: any) {
			message.error( e?.response?.data || e.error || "Something went wrong!");
		}
	};

	const handleEditAlias = () => {
		setIsAliasEdit(true);
	};

	const handleCompleteAlias = async () => {
		try {
			if (currentContract) {
				setIsAliasEdit(false);
				await api.setContractAlias(editingAlias, currentContract.id);
				dispatch(getContract(currentContract.id));
			}
		} catch (e: any) {
			message.error( JSON.stringify(e.response) || e.error || "Something went wrong!");
		}
	};

	const handleEditInformation = () => {
		setIsInformationEdit(true);
	};

	const handleCompleteInformation = async () => {
		try {
			if (currentContract) {
				setIsInformationEdit(false);
				await api.setContractInformation(editingInformation, currentContract.id);
				dispatch(getContract(currentContract.id));
			}
		} catch (e: any) {
			message.error( JSON.stringify(e.response) || e.error || "Something went wrong!");
		}
	};

	const handleExportPdf = async () => {
		try {
			if (currentContract) {
				api.contractsDownloadPdf(currentContract.id)
					.then((response) => {

						const url = window.URL.createObjectURL(new Blob([ response.data ]));
						const link = document.createElement("a");
						link.href = url;
						link.download = response.headers?.[ "content-disposition" ] || "downloaded-file";
						document.body.appendChild(link);

						link.click();

						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					})
					.catch((error) => {
						console.error("Error fetching the file:", error);
					});
			}
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	const handleExportXlsx = async () => {
		try {
			if (currentContract) {
				api.contractsDownloadXlsx(currentContract.id)
					.then((response) => {

						const url = window.URL.createObjectURL(new Blob([ response.data ]));
						const link = document.createElement("a");
						link.href = url;
						link.download = response.headers?.[ "content-disposition" ] || "downloaded-file";
						document.body.appendChild(link);

						link.click();

						document.body.removeChild(link);
						window.URL.revokeObjectURL(url);
					})
					.catch((error) => {
						console.error("Error fetching the file:", error);
					});
			}
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	const getItems = ({ currentContract }: { currentContract: IContract }): MenuItem[] => ([
		{
			key: "invite-contractor",
			label: "Invite contractor",
			disabled: !currentContract.granted_invite,
			onClick: () => setInviteContractorModal(currentContract?.customer?.official_name),
		},
		{
			key: "export",
			label: "Export",
			children: [
				{
					key: "1",
					label: (
						<div
							style={ { display: "flex", justifyContent: "space-between" } }
							onClick={ handleExportPdf }
						>
							<span>PDF</span> <span>.pdf</span>
						</div>
					),
				},
				{
					key: "2",
					label: (
						<div
							style={ { display: "flex", justifyContent: "space-between" } }
							onClick={ handleExportXlsx }
						>
							<span>Excel</span> <span>.xls</span>
						</div>
					),
				},
			],
		},
		{
			type: "divider",
		},
		{
			key: "terminate",
			label: "Terminate",
			itemIcon: (
				<img
					style={ { marginLeft: "85px", marginBottom: "-2px" } }
					src={ closeRed }
					alt=""
				/>
			),
			onClick: () => setIsTerminateModal(true),
		},
	]);

	const onClick: MenuProps["onClick"] = (e) => {
		setIsMenuVisible(false);
	};

	useEffect(() => {
		// getContractors("");
		getCategories();
	}, []);

	useEffect(() => {
		if (currentContract) {
			form.setFieldsValue(initialValues);
			setEditingAlias(currentContract.meta.alias);
			setEditingInformation(currentContract.meta.information);
		}
	}, [ currentContract ]);

	if (!contractEdit && currentContract) {
		return (
			<div className="contract-info-view-wrapper">
				<ContractTerminateModal
					id={ currentContract.id }
					isTerminateModal={ isTerminateModal }
					setIsTerminateModal={ setIsTerminateModal }
				/>

				<ContractPublishModal
					id={ currentContract.id }
					name={ inviteContractorModal }
					isPublishModal={ !!inviteContractorModal }
					setIsPublishModal={ setInviteContractorModal }
				/>

				<div className="contract-info-view">
					<div>
						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title">
								Contract number
							</div>

							<div className="contract-info-view-item-accent">
								{currentContract.identifier}
							</div>
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title">
								Alias
							</div>

							{isAliasEdit ? (
								<Input
									className="alias-input-mini"
									value={ editingAlias }
									onChange={ (e) => setEditingAlias(e.target.value) }
									suffix={ (
										<>
											<img
												alt=""
												height={ 14 }
												width={ 14 }
												src={ checkIcon }
												onClick={ handleCompleteAlias }
											/>

											<img
												alt=""
												onClick={ () => setIsAliasEdit(false) }
												src={ closeIcon }
											/>
										</>
									) }
									onClick={ (e) => e.stopPropagation() }
								/>
							) : (
								<>
									{currentContract.meta.alias ? (
										<div className="alias-edit" onClick={ handleEditAlias }>
											{currentContract.meta.alias}

											<img src={ editIcon } alt="" style={ { cursor: "pointer" } } />
										</div>
									) : (
										<div className="alias-add" onClick={ handleEditAlias }>
											<img src={ editIcon } alt="" style={ { cursor: "pointer" } } />

											Add
										</div>
									)}
								</>
							)}
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title">
								Status
							</div>

							<img
								alt=""
								// @ts-ignore
								src={ statusIcons[ currentContract?.status ] }
							/>

							<span style={ { textTransform: "capitalize" } }>
								{currentContract?.status}
							</span>
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title">
								Handshaked
							</div>

							{currentContract.handshake_completed ? (
								<div style={ { display: "flex", alignItems: "center", gap: 10 } }>
									<img src={ handshakeBlueMiniIcon } alt="" />
									Yes
								</div>
							) : (
								<div style={ { display: "flex", alignItems: "center", gap: 10 } }>
									<img src={ handshakeMiniIcon } alt="" />
									<span>
									No (
									<span
										className="link"
										onClick={ () => setInviteContractorModal(currentContract?.customer?.official_name) }
									>
									Invite
									</span>)
									</span>
								</div>
							)}
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title">
								Category
							</div>

							{currentContract.category?.name}
						</div>

						<div className="contract-info-view-item">
							<Button
								className="sla-button"
								type="link"
								onClick={ () => setSlaModalOpen(true) }
							>
								Service Level Agreement
								<img src={ arrowUp } alt="" />
							</Button>
						</div>
					</div>

					<div>
						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title" style={ { width: "80px" } }>
								Start date
							</div>
							{currentContract.date_begin}
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title" style={ { width: "80px" } }>
								End date
							</div>
							{currentContract.date_end}
						</div>

						<div className="contract-info-view-item">
							<div className="contract-info-view-item-title" style={ { width: "80px", marginRight: "150px" } }>
								Information
							</div>

							{isInformationEdit ? (
								<>
									<img
										alt=""
										height={ 14 }
										width={ 14 }
										src={ checkIcon }
										style={ { cursor: "pointer" } }
										onClick={ handleCompleteInformation }
									/>

									<img
										alt=""
										onClick={ () => setIsInformationEdit(false) }
										src={ closeIcon }
										style={ { cursor: "pointer" } }
									/>
								</>
							) : (
								<img
									alt=""
									src={ editIcon }
									style={ { cursor: "pointer" } }
									onClick={ handleEditInformation }
								/>
							)}
						</div>

						<div>
							{isInformationEdit ? (
								<Input.TextArea
									className="alias-input-mini"
									value={ editingInformation }
									onChange={ (e) => setEditingInformation(e.target.value) }
									rows={ 3 }
									onClick={ (e) => e.stopPropagation() }
									style={ { width: 280, resize: "none" } }
								/>
							) : (
								<div className="information-view">
									{currentContract.meta.information}
								</div>
							)}
						</div>
					</div>

					{isSlaModalOpen && (
						<ContractInfoSlaModal
							contractId={ currentContract.id }
							slaId={ slaIdNew || currentContract.sla_pk }
							isSlaModalOpen={ isSlaModalOpen }
							setSlaModalOpen={ setSlaModalOpen }
							setSlaIdNew={ setSlaIdNew }
						/>
					)}
				</div>

				<div className="contract-info-view-group">
					<div className="contract-info-view-widget">
						<div className="contract-info-view-widget-title">
							Customer
						</div>

						<div className="contract-info-view-widget-info">
							<img
								alt=""
								src={ currentContract.customer.has_account ? hydraAccountBlue : hydraAccount }
							/>

							{currentContract.customer.official_name}
						</div>
					</div>

					<div className="contract-info-view-widget">
						<div className="contract-info-view-widget-title">
							Subcontractor
						</div>

						<div className="contract-info-view-widget-info">
							<img
								alt=""
								src={ currentContract.service_provider.has_account ? hydraAccountBlue : hydraAccount }
							/>

							{currentContract.service_provider.official_name}
						</div>
					</div>
				</div>

				{currentContract.granted_edit ? (
					<div className="contract-info-view-buttons">
						<Button
							className="contract-info-view-button"
							onClick={ () => setContractEdit(true) }
						>
							Edit
						</Button>

						<Popover
							placement="bottomRight"
							open={ isMenuVisible }
							overlayInnerStyle={ { padding: "0px" } }
							content={ (
								<Menu
									onClick={ onClick }
									style={ { width: 200 } }
									mode="vertical"
									selectable={ false }
									items={ getItems({ currentContract }) }
								/>
							) }
							trigger="hover"
						>
							<Button
								className="contract-info-view-buttons-more"
								type="text"
								shape="circle"
								icon={ <img src={ dotsMenu } alt="" /> }
								onClick={ () => setIsMenuVisible(!isMenuVisible) }
							/>
						</Popover>
					</div>
				) : (
					<div className="view-only">
						<img src={ eyeGray } alt="" />

						View only
					</div>
				)}
			</div>
		)
	}

	return (
		<>
			{currentContract && (
				<ContractPublishModal
					id={ currentContract.id }
					name={ inviteContractorModal }
					isPublishModal={ !!inviteContractorModal }
					setIsPublishModal={ setInviteContractorModal }
				/>
			)}

			<Form
				name="contract-info-form"
				className="contract-info-form"
				layout="horizontal"
				autoComplete="off"
				form={ form }
				onFinish={ handleCompleteForm }
				initialValues={ initialValues }
			>
				<div className="contract-info-form-wrapper">
					<div className="contract-info-form-form">
						<div>
							<Form.Item
								label="Contract number::"
								name="identifier"
								rules={ [ { required: true, message: "Please input contract number!" } ] }
								style={ { marginBottom: 0, width: "100%" } }
							>
								<Input size="small" />
							</Form.Item>

							<Form.Item
								label="Alias"
								name="alias"
								style={ { marginBottom: 0, width: "100%" } }
							>
								<Input size="small" />
							</Form.Item>

							<div className="contract-info-view-item" style={ { gap: 28, height: 24 } }>
								<div className="contract-info-view-item-title">
									Status:
								</div>

								<div style={ { display: "flex", gap: 10 } }>
									<img
										alt=""
										// @ts-ignore
										src={ statusIcons[ currentContract?.status ] }
									/>

									<span style={ { textTransform: "capitalize" } }>{currentContract?.status}</span>
								</div>
							</div>

							<div className="contract-info-view-item" style={ { gap: 28, height: 24 } }>
								<div className="contract-info-view-item-title">
									Handshaked:
								</div>

								{currentContract?.handshake_completed ? (
									<div style={ { display: "flex", alignItems: "center", gap: 10 } }>
										<img src={ handshakeBlueMiniIcon } alt="" />
										Yes
									</div>
								) : (
									<div style={ { display: "flex", alignItems: "center", gap: 10 } }>
										<img src={ handshakeMiniIcon } alt="" />
										No
										<span
											className="link"
											onClick={ () => setInviteContractorModal(currentContract?.customer?.official_name || "") }
										>
											Invite
										</span>
									</div>
								)}
							</div>

							<Form.Item
								label="Category:"
								name="category"
								style={ { width: "100%" } }
							>
								<Select
									options={ categories }
									filterOption={ false }
									placeholder="Choose category"
									notFoundContent={ null }
									size="small"
								/>
							</Form.Item>

							<Form.Item
								label="Contractor:"
								name="contractor"
								// rules={ [ { required: true, message: "Please select contractor!" } ] }
								style={ { width: "100%" } }
							>
								<Select
									disabled={ true }
									options={ contractors }
									showSearch
									onSearch={ (value) => getContractors(value) }
									filterOption={ false }
									placeholder="Choose contractor"
									notFoundContent={ null }
									size="small"
								/>
							</Form.Item>

							{/*<Form.Item*/}
							{/*	label="Contract date"*/}
							{/*	name="date_of_signing"*/}
							{/*	style={ { marginBottom: 0, width: "100%" } }*/}
							{/*>*/}
							{/*	<DatePicker style={ { width: "100%" } } size="small" />*/}
							{/*</Form.Item>*/}

							{/*<Form.Item*/}
							{/*	label="End user"*/}
							{/*	name="end_user"*/}
							{/*	style={ { marginBottom: 0, width: "100%" } }*/}
							{/*>*/}
							{/*	<Input size="small" />*/}
							{/*</Form.Item>*/}
						</div>

						<div>
							<Form.Item
								label="Start Date"
								name="date_begin"
								style={ { marginBottom: 0, width: "100%" } }
							>
								<DatePicker style={ { width: "100%" } } size="small" />
							</Form.Item>

							<Form.Item
								label="End Date"
								name="date_end"
								style={ { marginBottom: 0, width: "100%" } }
							>
								<DatePicker style={ { width: "100%" } } size="small" />
							</Form.Item>

							<Form.Item
								label="Information"
								name="information"
								style={ { marginBottom: 0, width: "100%" } }
							>
								<Input.TextArea
									size="small"
									rows={ 3 }
									style={ { resize: "none" } }
								/>
							</Form.Item>

							<Form.Item
								label="Subcontractor:"
								name="subcontractor"
								style={ { width: "100%", marginTop: 46 } }
							>
								<Select
									disabled={ true }
									showSearch
									filterOption={ false }
									placeholder="Choose contractor"
									notFoundContent={ null }
									size="small"
								/>
							</Form.Item>
						</div>
					</div>

					<div className="contract-info-form-buttons">
						<Button
							onClick={ () => setContractEdit(false) }
						>
							Cancel
						</Button>

						<Button
							disabled={ isLoading }
							type="primary"
							htmlType="submit"
						>
							Save
						</Button>
					</div>
				</div>
			</Form>
		</>
	);
};

export default ContractInfoForm;
