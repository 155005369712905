import { FC, useEffect } from "react";

import { useAppDispatch } from "store";
import { getCases } from "store/cases/cases.thunks";
import { CasesFooter, CasesFilterMobile, CasesTableMobile, CasesMobileTable } from "components/cases";

import "./CasesPageMobile.scss";

const CasesPageMobile: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCases())
  }, [])

  return (
    <div className="default-page-wrapper mobile">
      <div className="cases-mobile-title">
        Cases
      </div>

      <CasesFilterMobile />
      {/*<CasesTableMobile />*/}
      <CasesMobileTable />
      <CasesFooter />
    </div>
  )
}

export default CasesPageMobile;
