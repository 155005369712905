import { FC, useEffect, useMemo, useState } from "react";

import { Form, Input, DatePicker, Select, Radio, Button } from "antd";
import dayjs from "dayjs";
import debounce from "lodash.debounce";

import {
  updateCasesFilter,
  updateCasesPagination,
  updateCasesSort,
} from "store/cases/cases.actions";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getCasesSelector } from "store/cases/cases.selector";
import { ICasesFilter, Severity } from "store/cases/cases.types";
import { getSeverityTitle } from "services/title.service";
import { useContractors } from "helpers/useContractors";
import { initialState } from "store/cases/cases.reducer";
import { getCases } from "store/cases/cases.thunks";
import searchIcon from "data/icons/search.svg";
import filterIcon from "data/icons/filter.svg";

import "./CasesFilterMobile.scss";

const severities = [
  {
    value: null,
    label: "All",
  },
  {
    value: Severity.CRITICAL,
    label: getSeverityTitle(Severity.CRITICAL),
  },
  {
    value: Severity.MAJOR,
    label: getSeverityTitle(Severity.MAJOR),
  },
  {
    value: Severity.NORMAL,
    label: getSeverityTitle(Severity.NORMAL),
  },
  {
    value: Severity.INFO,
    label: getSeverityTitle(Severity.INFO),
  },
]

interface IFilter extends ICasesFilter {
  date: any[]
}

const CasesFilterMobile: FC = () => {
  const dispatch = useAppDispatch();
  const { isAdmin, isMobile } = useAppSelector(getUserSelector);
  const { filter, pagination } = useAppSelector(getCasesSelector);
  const [ isFilterOpen, setIsFilterOpen ] = useState(false);

  const { contractors, getContractors } = useContractors(100);

  useEffect(() => {
    isAdmin && getContractors("");

    return () => {
      if (window.location.pathname.indexOf("/cases") === -1) {
        dispatch(updateCasesFilter(initialState.filter));
        dispatch(updateCasesSort(initialState.sort));
        dispatch(updateCasesPagination(initialState.pagination));
      }
    }
  }, [])

  const handleChangeFilter = (_: Partial<ICasesFilter>, values: IFilter) => {
    if (_.client && isAdmin) {
      getContractors("");
    }

    dispatch(updateCasesFilter({
      search: values.search,
      date_from: values.date && values.date[ 0 ] ? dayjs(values.date[ 0 ]).format("YYYY-MM-DD") : "",
      date_to: values.date && values.date[ 1 ] ? dayjs(values.date[ 1 ]).format("YYYY-MM-DD") : "",
      client: values.client || "",
      severity: values.severity || null,
      type: values.type,
    }));
    dispatch(updateCasesPagination({ page: 1, rowsPerPage: pagination.rowsPerPage }))
  }

  const updateInfo = () => {
    dispatch(getCases());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <Form
      className={ `filter-wrapper ${ isMobile ? "mobile" : "" }` }
      id="cases-filter"
      layout="vertical"
      initialValues={ filter }
      onValuesChange={ (e, values) => {
        handleChangeFilter(e, values);
        debouncedResults();
      } }
    >
      <div className="mobile-search-wrapper">
        <Form.Item
          name="search"
        >
          <Input
            prefix={ <img src={ searchIcon } alt="" /> }
            placeholder="Search for Case"
          />
        </Form.Item>
        <Button
          className="filter-icon"
          icon={ <img src={ filterIcon } alt="" /> }
          type="text"
          onClick={ () => setIsFilterOpen(!isFilterOpen) }
        />
      </div>

      <div className={ `mobile-filter ${ isFilterOpen ? "visible" : "" }` }>
        <Form.Item
          label="Start date"
          name="date_start"
        >
          <DatePicker
            style={ { width: "100%" } }
            placeholder="Start Date"
          />
        </Form.Item>

        <Form.Item
          label="End date"
          name="end_date"
        >
          <DatePicker
            style={ { width: "100%" } }
            placeholder="End Date"
          />
        </Form.Item>

        {isAdmin ? (
          <Form.Item
            label="Client"
            name="client"
          >
            <Select
              allowClear
              filterOption={ false }
              onSearch={ (value) => getContractors(value) }
              options={ contractors }
              showSearch
            />
          </Form.Item>
        ) : (
          <Form.Item
            label="Severity"
            name="severity"
          >
            <Select
              options={ severities }
            />
          </Form.Item>
        )}
        <Form.Item name="type" style={ { marginTop: "30px" } }>
          <Radio.Group>
            <Radio value="all">All</Radio>
            <Radio value="open">Open</Radio>
            <Radio value="closed">Closed</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </Form>
  )
}

export default CasesFilterMobile;
