import { FC } from "react";

import { Pagination } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { Loading } from "components/common";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import { updateEquipmentsPagination } from "store/listEquipment/listEquipment.actions";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";

const EquipmentsFooter: FC = () => {
	const dispatch = useAppDispatch();
	const { pagination, isEquipmentsLoading } = useAppSelector(getListEquipmentSelector);

	const handleUpdatePagination = (page: number, pageSize: number) => {
		dispatch(updateEquipmentsPagination(page))
		dispatch(getEquipments());
	}
	console.log(pagination);
	if (pagination.count === 0) {
		return null;
	}

	return (
		<Loading isLoading={ isEquipmentsLoading } isColored height={ 200 }>
			<div className="default-footer-wrapper mobile">
				{/*<Button type="primary">*/}
				{/*	{ isMobile ? "" : "Download cases" }*/}
				{/*	<img src={ downloadIcon } alt="" />*/}
				{/*</Button>*/}

				<Pagination
					total={ pagination.count }
					// showTotal={ (total, range) => isMobile ? "" : `${ range[ 0 ] }-${ range[ 1 ] } of ${ total } items` }
					pageSize={ pagination.rowsPerPage }
					current={ pagination.page }
					onChange={ handleUpdatePagination }
					// responsive
				/>
			</div>
		</Loading>
	)
}

export default EquipmentsFooter;
