import { FC, SetStateAction, useState } from "react";

import { Button, message, Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getCasesSelector } from "store/cases/cases.selector";
import caseIcon from "data/icons/case.svg";
import { FileHolder, Loading } from "components/common";
import closeIcon from "data/icons/close-gray.svg";
import api from "services/api.service";
import { getCurrentCase } from "store/cases/cases.thunks";
import { formatDate, getSeverityTitle } from "services/title.service";
import downloadIcon from "data/icons/downloads-dark-blue.svg";
import { getUserSelector } from "store/user/user.selector";
import arrowIcon from "data/icons/arrow-blue.svg";

import "./CurrentCaseCard.scss";

interface IProps {
  dataDisplay: "info" | "contacts";
  setDataDisplay: (data: "info" | "contacts") => void;
  onLoadActionsLog: () => Promise<void>;
}

const CurrentCaseCard: FC<IProps> = ({ dataDisplay, setDataDisplay, onLoadActionsLog }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentCase, isCurrentCaseLoading } = useAppSelector(getCasesSelector);
  const { isMobile, isAdmin } = useAppSelector(getUserSelector);

  const [ isCloseDialogOpen, setIsCloseDialogOpen ] = useState(false);
  const [ isCaseCloseLoading, setIsCaseCloseLoading ] = useState(false);
  const [ isFullDescription, setIsFullDescription ] = useState(false);
  const [ resolution, setResolution ] = useState<string>("Successfully Completed");

  const onResolutionChange = (e: { target: { value: SetStateAction<string | undefined>; }; }) => {
    setResolution(e.target.value as string);
  };
  
  const getCloseCaseDialog = () => {
    return (
      <Modal
        open={ isCloseDialogOpen }
        closeIcon={
          <img src={ closeIcon } alt="" onClick={ () => setIsCloseDialogOpen(false) } />
        }
        footer={ null }
        centered
        className="close-case-modal"
        width={ 360 }
        onCancel={ () => setIsCloseDialogOpen(false) }
      >
        <div className="close-case-content">
          <h4>Close case?</h4>

          <div className="close-case-label">
            Case Resolution:
          </div>

          <div className="close-case-textarea">
            <Input.TextArea
              rows={ 6 }
              onChange={ onResolutionChange }
              value={ resolution }
            />
          </div>

          <div className="buttons-wrapper">
            <Button type="primary" onClick={ closeCase } disabled={ isCaseCloseLoading }>
              <Loading isLoading={ isCaseCloseLoading }>
                Yes
              </Loading>
            </Button>
            <Button onClick={ () => setIsCloseDialogOpen(false) }>No</Button>
          </div>
        </div>
      </Modal>
    );
  }

  const closeCase = async () => {
    if (!currentCase) {
      return;
    }

    try {
      setIsCaseCloseLoading(true);
      await api.closeCase(currentCase.id, resolution);
      setIsCaseCloseLoading(false);
      setIsCloseDialogOpen(false);
      dispatch(getCurrentCase(currentCase.id.toString(), () => {
        navigate("/not-found");
      }));
      onLoadActionsLog();
    } catch (e: any) {
      setIsCaseCloseLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  return (
    <Loading isLoading={ isCurrentCaseLoading } isColored isFullWidth height={ 200 }>
      { currentCase && (
        <div className={ `current-case-card-wrapper ${ isMobile ? "mobile" : "" }` }>
          <div className="current-case-header-wrapper">
            <div className="current-case-icon-wrapper">
              <img src={ caseIcon } alt="" className="current-case-icon" />
            </div>
            <div className="case-name-wrapper">
              <p className="case-number">Case { currentCase.custom_id }</p>
              <p className="case-name">{ currentCase.subject }</p>
            </div>
            { currentCase.status === "open" && isAdmin && currentCase.granted_close && (
              <>
                <Button
                  className="close-case-button"
                  type="primary"
                  onClick={ () => setIsCloseDialogOpen(true) }
                >
                  Close case
                </Button>

                { getCloseCaseDialog() }
              </>
            ) }
          </div>
          { isMobile && (
            <div className="tab-buttons-wrapper">
              <Button type="primary" disabled={ dataDisplay === "info" } onClick={ () => setDataDisplay("info") }>Information</Button>
              <Button type="primary" disabled={ dataDisplay === "contacts" } onClick={ () => setDataDisplay("contacts") }>Contacts</Button>
            </div>
          ) }
          { (!isMobile || (isMobile && dataDisplay === "info")) ? (
            <>
              <div className="current-case-info-wrapper">
                <div className="current-case-info-column">
                  <div className="current-case-info-item">
                    <p className="title">Customer:</p>
                    <p className="value">{ currentCase.customer ? currentCase.customer.name : <span>—</span> }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Service provider:</p>
                    <p className="value">{ currentCase.customer ? currentCase.provider.name : <span>—</span> }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Product Number:</p>
                    <p className="value">{ currentCase.equipment ? currentCase.equipment.product.product_number : <span>—</span> }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Serial Number:</p>
                    <p className="value">{ currentCase.equipment ? currentCase.equipment.serial_number : currentCase.serial_number }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Product Description:</p>
                    <p className="value">{ currentCase.equipment ? currentCase.equipment.product.description : <span>—</span> }</p>
                  </div>
                </div>
                <div className="current-case-info-column">
                  <div className="current-case-info-item">
                    <p className="title">Severity:</p>
                    <p className="value">{ getSeverityTitle(currentCase.severity) }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Support Type:</p>
                    <p className="value">{ currentCase.equipment ? currentCase.equipment.sla_ci.marker : <span>—</span> }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Open Date:</p>
                    <p className="value">{ formatDate(currentCase.created_at) }</p>
                  </div>
                  <div className="current-case-info-item">
                    <p className="title">Close Date:</p>
                    <p className="value">{ currentCase.closed_at ? formatDate(currentCase.closed_at) : <span>—</span> }</p>
                  </div>
                </div>
              </div>
              <div className={ isMobile ? `description-wrapper ${ isFullDescription ? "full" : "" }` : "description-wrapper" }>
                <p className="title">Problem Description:</p>
                <p className="value" style={ { whiteSpace: "pre-wrap" } }>{ currentCase.text }</p>
                { isMobile && currentCase.text && currentCase.text.length >= 400 ? (
                  <Button onClick={ () => setIsFullDescription(!isFullDescription) } type="text">{ isFullDescription ? "Hide description" : "See full description" } <img src={ arrowIcon }/></Button>
                ) : null }
              </div>
            </>
          ) : (
            <div className="current-case-info-wrapper">
              <div className="current-case-info-column">
                <div className="current-case-info-item">
                  <p className="title">Name :</p>
                  <p className="value">{ currentCase.contact_name }</p>
                </div>
                <div className="current-case-info-item">
                  <p className="title">Email :</p>
                  <p className="value">{ currentCase.contact_email }</p>
                </div>
                <div className="current-case-info-item">
                  <p className="title">Phone :</p>
                  <p className="value"><a href={ "tel:" + currentCase.contact_phone_number }>{ currentCase.contact_phone_number }</a></p>
                </div>
              </div>
            </div>
          ) }
          <div className="attachments-wrapper">
            <p className="title">
              { currentCase.attachments.length } Attachments
              <img className="download-icon" src={ downloadIcon } alt="" />
            </p>
          </div>

          <div className="attachments-list">
            { currentCase.attachments.map((
              // @ts-ignore
              { file, original_file_name },
            ) => (
              <FileHolder
                key={ file }
                file={ file }
                original_file_name={ original_file_name }
              />
            ))}
          </div>

          {/*{ isMobile && isAdmin && (*/}
          {/*  <div className="task-link-wrapper">*/}
          {/*    <p className="task-link-title">Task link</p>*/}
          {/*    <Tooltip title="Click to copy URL">*/}
          {/*      <div*/}
          {/*        onClick={ () => {*/}
          {/*          try {*/}
          {/*            navigator.clipboard.writeText(window.location.href);*/}
          {/*            message.success("Link copied!")*/}
          {/*          } catch (e) {*/}
          {/*            message.error("Something went wrong!")*/}
          {/*          }*/}
          {/*        } }*/}
          {/*        className="task-link-item"*/}
          {/*      >*/}
          {/*        <div className="link-icon-wrapper">*/}
          {/*          <img src={ fileIcon } alt="" />*/}
          {/*          <img src={ linkIcon } alt="" />*/}
          {/*        </div>*/}
          {/*        <p className="link">{ window.location.href }</p>*/}
          {/*      </div>*/}
          {/*    </Tooltip>*/}
          {/*  </div>*/}
          {/*) }*/}
        </div>
      ) }
    </Loading>
  )
}

export default CurrentCaseCard;
