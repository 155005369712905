import { FC, useEffect, useState } from "react";

import { Tag } from "antd";
import { useNavigate } from "react-router-dom";

import { Loading } from "components/common";
import { useAppSelector } from "store";
import { getListEquipmentSelector } from "store/listEquipment/listEquipment.selector";
import "components/cases/CasesTable/CasesTable.scss";
import { RouteLinks } from "services/router.service";
import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";
import checkGreen from "data/icons/check-green.svg";
import hydraAccount from "data/icons/hydra-account.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";

import "./ListEquipmentTableMobile.scss";

const statusIcons = {
  active: checkGreen,
  draft: pending,
  pending: pending,
  expired: expired,
};

const ListEquipmentTableMobile: FC = () => {
  const navigate = useNavigate();

  const { isEquipmentsLoading, equipments } = useAppSelector(getListEquipmentSelector);
  const [ updateState, setUpdateState ] = useState(false);

  useEffect(() => {
    setUpdateState(!updateState);
  }, [ isEquipmentsLoading ]);

  return (
    <Loading
      isLoading={ isEquipmentsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <div className="equipments-container">
        {equipments.map(item => (
          <div
            className="equipments-item"
            key={ item.id }
            onClick={ () => navigate(RouteLinks.PROTECTED.LIST_EQUIPMENT + "/" + item.id) }
          >
            <div className="case-item-title">
              {item.is_active && (
                <img alt="" src={ statusIcons.active } />
              )}
              {item.is_pending && (
                <img alt="" src={ statusIcons.pending } />
              )}
              {!item.is_active && !item.is_pending && (
                <img alt="" src={ statusIcons.expired } />
              )}
              <span className="case-item-title-text">
                {item.serial_number}
              </span>

              {item.sla_ci && (
                <div className="case-item-title-tooltip">
                  <Tag
                    style={ {
                      background: item.sla_ci.marker_bg_color,
                      color: item.sla_ci.marker_text_color,
                      margin: 0,
                    } }
                  >
                    {item.sla_ci.marker}
                  </Tag>
                </div>
              )}
            </div>

            <div className="case-item-content">
              {item.description}
            </div>

            <div className="case-item-info">
              <img
                alt=""
                className="case-item-info-icon"
                src={ item.has_account ? hydraAccountBlue : hydraAccount }
              />

              <span>
                {item.customer_official_name}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Loading>
  )
}

export default ListEquipmentTableMobile;
