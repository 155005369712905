import { useEffect } from "react";

import {
	ListEquipmentFilterMobile,
	ListEquipmentTableMobile,
} from "components/listEquipmentMobile";
import { useAppDispatch } from "store";
import { getEquipments } from "store/listEquipment/listEquipment.thunks";
import { EquipmentsFooter } from "components/equipment";

import "./ListEquipmentPageMobile.scss";

const ListEquipmentPageMobile = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getEquipments());
	}, [])

	return (
		<div className="default-page-wrapper mobile">
			<div className="list-equipment-mobile-title">
				List of equipment
			</div>

			<ListEquipmentFilterMobile />
			<ListEquipmentTableMobile />
			<EquipmentsFooter />
		</div>
	);
};

export default ListEquipmentPageMobile;
