import { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Tag } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import grayDotIcon from "data/icons/dot-gray.svg";
import { formatDate } from "services/title.service";
import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";
import checkGreen from "data/icons/check-green.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import hydraAccount from "data/icons/hydra-account.svg";
import api from "services/api.service";

import "./CurrentEquipmentPageMobile.scss";

const statusIcons = {
	active: checkGreen,
	draft: pending,
	pending: pending,
	expired: expired,
};

const CurrentEquipmentPageMobile = () => {
	const navigate = useNavigate();
	const { equipmentId } = useParams();
	const dispatch = useAppDispatch();

	const [ equipment, setEquipment ] = useState<IEquipmentSource>();

	const getCurrentEquipment = async (equipmentId: string) => {
		try {
			const response = await api.getEquipment(equipmentId);

			setEquipment(response.data);
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	useEffect(() => {
		equipmentId && getCurrentEquipment(equipmentId);
	}, [])

	if (!equipment) {
		return null;
	}

	return (
		<div className="default-page-wrapper current-case-page-wrapper">
			<Button
				className="back-button"
				type="link"
				onClick={ () => navigate(RouteLinks.PROTECTED.LIST_EQUIPMENT) }
			>
				<img src={ backIcon } alt="" />
				Back
			</Button>

			<div>
				<div
					className="equipment-item"
					// onClick={ () => navigate(RouteLinks.PROTECTED.CASES.CASE + "/" + item.id) }
				>
					<div className="case-item-title">
						{equipment.is_active && (
							<img alt="" src={ statusIcons.active } />
						)}
						{equipment.is_pending && (
							<img alt="" src={ statusIcons.pending } />
						)}
						{!equipment.is_active && !equipment.is_pending && (
							<img alt="" src={ statusIcons.expired } />
						)}

            <span className="case-item-title-text">
              {equipment.serial_number}
            </span>

						<img
							className="case-item-title-icon"
							src={ grayDotIcon }
							alt=""
						/>

						<span className="case-item-title-date">
              {equipment.product.product_number}
							{/*{formatDate(equipment.date_begin)}*/}
						</span>

						{equipment.sla_ci && (
							<div className="case-item-title-tooltip">
								<Tag
									style={ {
										background: equipment.sla_ci.marker_bg_color,
										color: equipment.sla_ci.marker_text_color,
										margin: 0,
									} }
								>
									{equipment.sla_ci.marker}
								</Tag>
							</div>
						)}
					</div>

					<div className="case-item-content">
						{equipment.product.description}
					</div>

					<div className="case-item-info">
						<img
							alt=""
							className="case-item-info-icon"
							src={ equipment.contract.contractor.has_account ? hydraAccountBlue : hydraAccount }
						/>

						<span>
							{equipment.contract.contractor.official_name}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CurrentEquipmentPageMobile;
